<template>
	<div>
		<div>
			<headerTop></headerTop>
		</div>
		<div>
			<headerMin></headerMin>
		</div>
		<navlist></navlist>
		<div class="banner">
			<el-carousel :loop='true' trigger="click" :height="400 + 'px'">
				<el-carousel-item v-for="(v,i) in banner_List" :key="i">
					<img class="banner_img" :src="v.image" alt />
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="mbx"><span>首页</span>>代理商专区</div>
		<div class="tabnavs">
			<div @click="recommend_Tab(v,i)" :class="{ recommend_Active : recommendNavActive == i}" v-for="(v,i) in recommendNav"
			 :key="i">
				{{v.category_name}}
				<i></i>
				<span></span>
			</div>
		</div>
		<div class="goods" v-if="recommend_List.length>0">
			<div @click="GoDetails(v.id,v.shop_id)" v-for="(v,i) in  recommend_List" :key="i">
				<div class="goodsImg">
					<img :src="v.goods_img" alt />
				</div>
				<p class="one">￥ {{v.price}}</p>
				<p class="two">{{v.goods_name}}</p>
				<div class="bottom">
					<p>
						评论
						<span>{{v.commentSum}}</span>条
					</p>
					<button>立即购买</button>
				</div>
			</div>
		</div>
		<div class="tishi" v-else>
			<h1>该类别下暂无商品</h1>
		</div>
		<div style="height: 50px;position: relative;">
			<el-pagination background layout="prev, pager, next" @current-change="getnewgoods" :total="this.total">
			</el-pagination>
		</div>
		<publicBottom></publicBottom>
	</div>
</template>

<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import navlist from "@/components/home_repect/navlist.vue";
	import publicBottom from "@/components/public/public_bottom.vue";
	import {
		getAllCate
	} from "@/request/index"; //  首页获取分类
	import {
		banner
	} from "@/request/index"; // 轮播图
	export default {
		name: "home",
		banner_List: [], //轮播图
		components: {
			headerTop,
			headerMin,
			navlist,
			publicBottom
		},
		data() {
			return {
				banner_List: [],
				recommendNavActive: 0, // 推荐 active
				recommendNav: [], //推荐 NAV
				recommend_List: [],
				category_id: "",
				page: "",
				total: "",
			}
		},
		created() {
			banner({
				position_id: 2,
				limit_num: 5
			}).then(res => {
				this.banner_List = res.data;
			});
			getAllCate({
				parent_id: 0
			}).then(res => {
				this.recommendNav = res.data;
				this.category_id = this.recommendNav[0].id;
				this.$axios.post("/api/Goods/index2", {
					category_id: this.category_id,
					is_daili: 1,
					page: 1,
					token: this.$token
				}).then(res => {
					if (res.data.code == 1) {
						this.total = res.data.data.total;

						this.recommend_List = res.data.data.data;
					}
				});
			});
		},
		methods: {
			getnewgoods(page) {
				this.$axios.post("/api/Goods/index2", {
					category_id: this.category_id,
					is_daili: 1,
					page: page,
					token: this.$token
				}).then(res => {
					if (res.data.code == 1) {
						this.total = res.data.data.total;
						this.recommend_List = res.data.data.data;
						console.log(this.recommend_List);
					} else {
						this.recommend_List = [];
					}
				});
			},
			recommend_Tab(v, i) {
				this.recommendNavActive = i;
				this.category_id = v.id;
				this.$axios.post("/api/Goods/index2", {
					category_id: this.category_id,
					is_daili: 1,
					page: 1,
					token: this.$token
				}).then(res => {
					if (res.data.code == 1) {
						this.total = res.data.data.total;
						this.recommend_List = res.data.data.data;
						console.log(this.recommend_List);
					} else {
						this.recommend_List = [];
					}
				});
			},
			GoDetails(id, shopId) {
				this.$router.push({
					path: "goodsDetails?id=" + id + "&shopId=" + shopId
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-pagination {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.tishi {
		line-height: 300px;
		text-align: center;
		font-size: 18px;
		color: #E5E5E5;
	}

	.mbx {
		line-height: 60px;
		margin-left: 80px;

		span {
			cursor: pointer;
		}
	}

	.goods {
		margin: 20px 0 60px 60px;
		overflow: hidden;

		>div {
			cursor: Pointer;
			width: 250px;
			height: 418px;
			padding: 6px;
			text-align: left;
			border: 1px solid #e6e6e6;
			position: relative;
			float: left;
			margin-right: 50px;
			margin-bottom: 20px;
		}

		.bottom {
			width: 100%;
			position: absolute;
			// left : 10px;
			bottom: 14px;
		}

		.goodsImg {
			width: 100%;
			height: 234px;
			margin-bottom: 10px;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.one {
			font-size: 24px;
			color: #f00000;
			margin-bottom: 8px;
		}

		.two {
			font-size: 16px;
			margin-bottom: 4px;
		}

		p {
			font-size: 16px;
			margin-bottom: 10px;

			span {
				color: #f00000;
			}
		}

		button {
			display: block;
			width: 236px;
			height: 32px;
			background: #f00000;
			font-size: 16px;
			border-radius: 4px;
		}
	}

	.tabnavs {
		cursor: pointer;
		margin: auto;
		width: 1200px;
		height: 42px;
		font-size: 18px;
		padding-left: 20px;
		display: flex;
		border-bottom: 1px solid #e6e6e6;

		>div {
			box-sizing: content-box;
			padding-left: 14px;
			padding-right: 14px;
			line-height: 42px;
			position: relative;
		}

		i {
			position: absolute;
			top: 14px;
			right: -1px;
			display: block;
			width: 1px;
			height: 18px;
			border-right: 1px solid #e6e6e6;
		}

		// 推荐产品 选中
		.recommend_Active {
			color: #38eec1;
			border: 1px solid #e6e6e6;

			i {
				border: none !important;
			}

			span {
				position: absolute;
				bottom: -2px;
				left: 0;
				display: block;
				width: 100%;
				height: 4px;
				background: white;
			}
		}
	}
</style>
